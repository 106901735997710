import React, { useContext } from "react";
import { PhotoEntry } from "../components";
import { PhotoContext, WarningContext } from "../contexts";
import shortid from "shortid";
import Resizer from "react-image-file-resizer";
import photoSelect from "../images/photo_select.png";
import photoCamera from "../images/photo_camera.png";
// We import the worker directly for use with pdfjs
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

const PhotoSection = ({ id }) => {
  // Inline Styling
  const btnStyle = {
    maxWidth: "64px",
    width: "auto",
    height: "auto",
  };

  // Using legacy require rather than module import due to compatibility
  const pdfjs = require("pdfjs-dist/legacy/build/pdf.js");
  // Need to specify worker manually when using legacy require
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [photos, setPhotos] = useContext(PhotoContext);
  const [warnings, setWarnings] = useContext(WarningContext);
  const addWarning = (rid, m) => {
    var found = false;
    warnings.map((warning) => {
      if (warning.refid === rid && warning.message === m) {
        found = true;
      }
      return null;
    });
    if (!found) {
      setWarnings((prev) => [
        ...prev,
        {
          refid: rid,
          message: m,
        },
      ]);
    }
  };
  const removeWarning = (rid) => {
    setWarnings((warnings) =>
      warnings.filter((warning) => warning.refid !== rid)
    );
  };

  // Detect Mobile
  function detectMobile() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }
  const isMobile = detectMobile();

  // Simulate click on the input to use the camera
  function useCamera() {
    document.getElementById(id + "_CameraInput").click();
  }
  // Simulate click on the input to select existing files
  function pickPhotos() {
    document.getElementById(id + "_PhotoSelect").click();
  }

  function dragOver(e) {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  }
  function dropAction(e) {
    e.stopPropagation();
    e.preventDefault();
    acceptFiles(e.dataTransfer.files);
  }
  function acceptFiles(fileList) {
    // Iterate through fileList, ensure they are images
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/(^image\/)(heif|heic|jpg|jpeg|tiff|png)/)) {
        // Resize image if it exceeds bounds, and convert it to JPG, then base64 encode it.
        Resizer.imageFileResizer(
          fileList[i], // Image file to be resized/converted
          1024, // maxWidth of the new image
          1024, // maxHeight of the new image
          "JPEG", // compressFormat of new image
          100, // quality of the image
          0, // rotation
          (uri) => {
            // callback function of the new image URI
            setPhotos((photos) => [
              ...photos,
              {
                refid: id,
                id: shortid.generate(),
                imgname: fileList[i].name, // Here we store unconverted name and imgurl to display in PhotoEntry.js
                imgurl: URL.createObjectURL(fileList[i]),
                file: uri,
              },
            ]);
            // Clear message if one exists.
            removeWarning(id + "_m");
          },
          "base64" // output type of the new image
        );
      } else if (fileList[i].type.match(/(^application\/)(pdf)/)) {
        // For multi-page PDFs, workingName helps differentiate documents
        const workingName = `doc-${shortid.generate()}`;
        // Get the PDF document
        pdfjs
          .getDocument({ url: URL.createObjectURL(fileList[i]) })
          .promise.then((pdfDoc) => {
            // Find out how many pages the document has
            const totalPages = pdfDoc.numPages;
            // For each page, render page to hidden canvas element, and then use image from canvas.
            for (let ii = 1; ii <= totalPages; ii++) {
              // For a PDF we need to render it to a canvas then export from canvas as an image.
              // We're just going to use regular javascript to make a quick and dirty hidden canvas element.
              let canvas = document.createElement("canvas");
              canvas.setAttribute("hidden", true);
              canvas.setAttribute("id", `canvas${ii}`);
              let canvasContext = canvas.getContext("2d");
              // Specify the page of the PDF
              pdfDoc.getPage(ii).then((page) => {
                // Since canvas is hidden, just get the viewport of the pdf, and set dimensions for that.
                // Viewport is retrieved using a pdfjs method, so we need to check the viewBox property for width and height
                let viewport = page.getViewport({ scale: 1 });
                canvas.width = viewport.viewBox[2];
                canvas.height = viewport.viewBox[3];
                // Create a renderContext object for page.render
                let renderContext = {
                  canvasContext,
                  viewport,
                };
                // Render target page to canvas element
                page.render(renderContext).promise.then(() => {
                  // Once rendered, export the image and handle as usual
                  canvas.toBlob(
                    (blob) => {
                      // Convert our Blob to a File object (add name and lastModifiedDate)
                      blob.lastModifiedDate = new Date();
                      blob.name = `${workingName}-${ii}of${totalPages}`;
                      // Use imageFileResizer just like we do when a photo is selected.
                      Resizer.imageFileResizer(
                        blob, // Image file to be resized/converted
                        1024, // maxWidth of the new image
                        1024, // maxHeight of the new image
                        "JPEG", // compressFormat of new image
                        100, // quality of the image
                        0, // rotation
                        (uri) => {
                          // callback function of the new image URI
                          setPhotos((photos) => [
                            ...photos,
                            {
                              refid: id,
                              id: shortid.generate(),
                              imgname: blob.name,
                              imgurl: URL.createObjectURL(blob),
                              file: uri,
                            },
                          ]);
                          // Clear message if one exists.
                          removeWarning(id + "_m");
                          // Destroy Canvas since it has served its' purpose
                          canvas.remove();
                        },
                        "base64" // output type of the new image
                      );
                    },
                    "image/jpeg",
                    1
                  );
                });
              });
            }
          });
      } else {
        // Inform user incorrect file type.
        addWarning(
          id + "_m",
          "  *Unacceptable image or pdf format! (Allowed formats: heif, heic, jpg, jpeg, tiff, png, pdf)  "
        );
      }
    }
  }
  return (
    <div
      id={id + "_DropTarget"}
      onDragOver={(e) => dragOver(e)}
      onDrop={(e) => dropAction(e)}
      className="container-fluid"
    >
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          {isMobile ? (
            <div className="col-auto">
              <center>
                <input
                  id={id + "_PhotoSelect"}
                  type="file"
                  accept="image/*, .pdf"
                  onChange={(e) => acceptFiles(e.target.files)}
                  hidden
                  multiple
                />
                <button
                  id={id + "_InputButton"}
                  className="btn btn-dark center-block"
                  type="button"
                  onClick={pickPhotos}
                  style={btnStyle}
                >
                  <img
                    src={photoSelect}
                    alt="PhotoSelect"
                    className="img-fluid"
                  />
                </button>
              </center>
            </div>
          ) : (
            <div className="col-auto">
              <center>
                <input
                  id={id + "_PhotoSelect"}
                  type="file"
                  onChange={(e) => acceptFiles(e.target.files)}
                  hidden
                  multiple
                />
                <button
                  id={id + "_InputButton"}
                  className="btn btn-dark center-block"
                  type="button"
                  onClick={pickPhotos}
                >
                  <font id="FrazerFont_Medium">
                    Click Here to Select File(s)
                  </font>
                </button>
              </center>
            </div>
          )}
          {isMobile && (
            <div className="col-auto">
              <center>
                <input
                  className="form-control"
                  id={id + "_CameraInput"}
                  type="file"
                  accept="image/*"
                  onChange={(e) => acceptFiles(e.target.files)}
                  capture="environment"
                  hidden
                />
                <button
                  id={id + "_CameraButton"}
                  type="button"
                  className="btn btn-dark center-block"
                  onClick={useCamera}
                  style={btnStyle}
                >
                  <img src={photoCamera} alt="Camera" className="img-fluid" />
                </button>
              </center>
            </div>
          )}
        </div>
        {/* <canvas key="pdfCanvas" id="pdfCanvas" width="100%" hidden/> */}
      </div>
      <br />
      <div className="container-fluid">
        {photos.length > 0 &&
          photos.map((photo) => {
            // Only render photo entries that have the same refid
            if (photo.refid === id) {
              return (
                <PhotoEntry
                  key={photo.id}
                  id={photo.id}
                  imgname={photo.imgname}
                  imgurl={photo.imgurl}
                />
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default PhotoSection;

import React,{ useContext, useState } from 'react';
import { LittleMessage } from '../components';
import ModalWarning from './ModalWarning'
import { OwnerContext, PhotoContext, WarningContext } from '../contexts';
import { navigate } from '@reach/router';

const SubmitSection = () => {
    // State/Context
    const [entries] = useContext(OwnerContext);
    const [photos] = useContext(PhotoContext);
    const [warnings, setWarnings] = useContext(WarningContext);
    const [modal, setModal] = useState(false)

    const addWarning = (rid, m) => {
        var found = false;
        for (let i=0; i<warnings.length; i++) {
            if (warnings[i].refid === rid) {
                found = true
            }
        }
        if (!found) {
            setWarnings(prev => [...prev,{
                refid: rid,
                message: m
            }]);
        }
    }
    const removeWarning = (rid) => {
        setWarnings(warnings => warnings.filter(warning => warning.refid !== rid));
    }

    // Validation!
    const finalValidate = () => {
        // Set this to true, anything we find wrong will set this to false preventing submission.
        var ready = true;
        // Iterate through and validate the fields.
        for (let i = 0; i < entries.length; i++) {
          // Business type does not have fields
          if (entries[i].entryrole !== "business") {
            // UserSelect idtype field
            if (entries[i].idtype === "dl" || entries[i].idtype === "passport" || entries[i].idtype === "gov") {
              //good
              removeWarning(entries[i].id + "_ownerIDtype_m");
            } else {
              // Tampered with
              addWarning(entries[i].id + "_ownerIDtype_m", "*This field is not valid, you may need to try a different Web Browser");
              ready = false;
            }


            // Verify that the UserField (name and idnumber) aren't empty strings (and contain more than just whitespace)
            if (entries[i].name.trim().length < 1) {
              // Empty string, not valid
              addWarning(entries[i].id+"_ownerName_m", "*Please fill out the above field.");
              ready = false;
            } else {
              // Clear any messages.
              removeWarning(entries[i].id+"_ownerName_m");
            }
            
            if (entries[i].idnumber.trim().length > 1) {
              removeWarning(entries[i].id + "_ownerIDnum_m")
            } else {
              addWarning(entries[i].id + "_ownerIDnum_m", "*Please fill out the above field.")
              ready = false
              // Next, verify that there is at least 1 photo associated with this entry.
              var numphotos = 0;
              for (let ii = 0; ii < photos.length; ii++) {
                if (photos[ii].refid === entries[i].id) {
                  // Matching reference, increase number of photos associated with the entry.
                  numphotos++;
                }
              }
              if (numphotos < 1) {
                // No associated photo
                addWarning(entries[i].id + "_m", "*At least one photo is required.");
                ready = false;
              } else {
                // Clear any message
                removeWarning(entries[i].id + "_m");
              }
            }
          }
        }

        if (entries.length === 0 &&  photos.length === 0) {
          // No Owner/Admin entries, and no photos uploaded.
          // Adding this check now that Owner isnt required.
          ready = false;
        }

        // Everything should be validated. Submit
        if (ready === true) {
            navigate('/submit');
        } else {
            // Inform user about missing fields.
              addWarning("submit_m", "*Missing or Invalid Entries, please check the above fields. At least one upload is required.");
            // Display popup to better alert user
            setModal(true)
        }
    }
    
    

    return (
        <div className="card">
            <div className="card-header">
                <font id="FrazerFont_Large">Submit</font>
            </div>
            <div className="card-body px-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <br/>
                        <br/>
                        <font id="FrazerFont_Medium"><span id="submit_m">Once you have everything completed, click Submit.</span></font>
                        <br/>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col">
                        <center>
                            <button className="btn btn-dark" type="button" onClick={finalValidate}>
                                <font id="FrazerFont_Medium">Submit</font>
                            </button>
                        </center>
                        </div>
                    </div>
                    <center>
                        <LittleMessage id={ "submit_m" } />
                    </center>
                    { modal && (
                        <ModalWarning handleModal={ setModal } />
                    )}
                </div>
            </div>
        </div>
    )
}

export default SubmitSection
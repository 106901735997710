import React from 'react';
import { navigate } from '@reach/router';
import redX from '../images/red_x_128.png'

const SubmitFail = ({ failmessage }) => {
    // Styling
    const imgStyle = {
        width: 'auto',
        height: 'auto',
        maxWidth: '96px',
        minWidth: '48px'
    }
    // Retry sending
    const retrySending = () => {
        // Send them back to previous page to try to resubmit.
        navigate('/');
    }
    return (
        <center>
            <img style={ imgStyle } src={redX} alt="Sending Failed" className="img-fluid" />
            <br/>
            <br/>
            <font id="FrazerFont_Medium">{ failmessage }</font>
            <br/>
            <br/>
            <font id="FrazerFont_Medium">Here are some steps you can try to resolve the issue:</font>
            <br/><br/>
            <ul className="list-group-flush list-unstyled">
                <li className="list-group-item"><font id="FrazerFont_Medium">Click "Retry" to go back and try to Submit again.</font></li>
                <li className="list-group-item"><font id="FrazerFont_Medium">If issues still persist, try a different Web Browser.</font></li>
                <li className="list-group-item"><font id="FrazerFont_Medium">Ensure you are on the correct network, or try a different device.</font></li>
            </ul>
            <br/>
            <br/>
            <button className="btn btn-dark" type="button" onClick={ retrySending }>
                <font id="FrazerFont_Medium">Retry?</font>
            </button>
            
        </center>
    )
}

export default SubmitFail
import React from 'react';
import { OwnerSection, OptionalSections, SubmitSection } from '../components';

const PageForm = ({ dealerinfo }) => {
    
    return (
        <div>
        <OwnerSection 
            title="Ownership" 
            role="Owner" 
            cid={ dealerinfo.cid }
            companyname={ dealerinfo.businessName }
            street={ dealerinfo.street }
            city={ dealerinfo.city }
            state={ dealerinfo.state }
            zip = { dealerinfo.zip }
        />
        <OptionalSections />
        <SubmitSection />
        </div>
    )
}

export default PageForm
import React from 'react'

const ModalWarning = ({ handleModal }) => {
    // The gray background
    const backdropStyle = {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        padding: 50
    };
    return (
        <div style={ backdropStyle } onClick={() => handleModal(false)}>
            <div class="modal show" tabindex="-1" role="dialog" style={{display: 'block'}}>
                <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                        <div class="modal-header danger">
                            <h5 class="modal-title">Incomplete Form</h5>
                            <button type="button" class="close" onClick={ () => handleModal(false) } aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>Please fill in all required fields and add at least one photo to submit your request.</p>
                        </div>
                        <div class="modal-footer">
                            <center>
                            <button type="button" class="btn btn-dark" onClick={ () => handleModal(false) }>
                                <font id="FrazerFont_Medium">Close</font>
                            </button>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalWarning
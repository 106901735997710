import React from 'react';

const Footer = () => {
    var d = new Date();
    return (
        <div>
            <br/>
            <center>
                <p style={{ color: '#ffffff' }}>Copyright © { d.getFullYear() } Frazer Computing, LLC. All Rights Reserved.</p>
            </center>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default Footer
import React, { useContext, useState, useEffect } from 'react';
import { ProgressBar, SubmitSuccess, SubmitFail } from '../components';
import { OwnerContext, PhotoContext } from '../contexts';
import { navigate } from '@reach/router';

const PageSubmit = ({ dealerinfo, link }) => {
    // State/Context
    const [entries] = useContext(OwnerContext);
    const [photos] = useContext(PhotoContext);
    // Progress Tracking and Percentage to pass into ProgressBar
    const [percent, setPercent] = useState(0);
    const [currentnum, setCurrentnum] = useState(0);
    const [maximumnum, setMaximumnum] = useState(0);
    // Determine what to present to the user (Preparing, Sending, Success, Error)
    const [status, setStatus] = useState("Preparing");
    const [failmessage, setFailmessage] = useState("");
    // URL for back-end submission endpoint
    let url = `${process.env.REACT_APP_BACKEND_URL}/portal/submitRecord?link=${link}`

    
    // Prepare for submission
    const handleSubmit = () => {
        // Used in determineSend()
        var sendcount = 0;
        // We don't want to send the data before its' prepared, so let's make sure its' ready.
        const determineSend = () => {
            // Called when photo is finished being converted, so increment sendcount
            sendcount++;
            // Update our percentage for our ProgressBar
            setCurrentnum(sendcount);
            setPercent(Math.round((sendcount / photos.length)*100))
            // Have we converted all photos?
            if (sendcount === photos.length) {
                // Send it! (And reset the count in case they need to submit again)
                setStatus("Sending");
                setPercent(0);
                sendData();
                sendcount = 0;
            }
        }

        // Fresh array to populate only the fields we want to transmit
        var data = [];
        // Ensure we actually have photos, if we don't return to previous page.
        // This happens when you refresh the page while on /submit
        if (photos.length <= 0) {
            navigate('/');
        }
        setMaximumnum(photos.length);
        // Iterate through entries from our OwnerContext
        for (let i=0; i<entries.length; i++) {
            // Go through PhotoContext and make sure we grab all associated photos relative to this entry's id
            for (let ii=0; ii<photos.length; ii++) {
                if (photos[ii].refid === entries[i].id) {
                    // Create a "record"
                    data.push({
                        entryrole: entries[i].entryrole,
                        name: entries[i].name,
                        idtype: entries[i].idtype,
                        idnumber: entries[i].idnumber,
                        photo: photos[ii].file,
                    });
                    // Determine if we have all photos and are ready to send.
                    determineSend();
                }
            }
        }
        // Sending the data
        async function sendData() {
            // Iterate through, send a "record" individually.
            // Hold an array for records that failed to send.
            var retry = [];
            setCurrentnum(0);
            setMaximumnum(data.length);
            // We need to track how many have been sent, so we'll decrement needToSend as we send them successfully.
            var needToSend = data.length;
            for (var i=0; i<data.length; i++) {
                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(data[i]),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    if (response.status === 200) {
                        // Success
                        setCurrentnum(i);
                        setPercent(Math.round((i / data.length)*100));
                        needToSend--;
                    } else {
                        // Record failed to submit, add to array to retry
                        retry.push(data[i]);
                    } 
                } catch (error) {
                    // Error, break out of the loop
                    break
                }
            }
            // Retry
            if (retry.length > 0) {
                setMaximumnum(retry.length);
                for (var ii=0; i<retry.length; ii++) {
                    try {
                        const response = await fetch(url, {
                            method: 'POST',
                            body: JSON.stringify(retry[ii]),
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                        if (response.status === 200) {
                            // Success
                            setCurrentnum(i);
                            setPercent(Math.round((ii / retry.length)*100));
                            needToSend--;
                        } // If it is not 200, it will be caught with needToSend not being 0
                    } catch (error) {
                        // Error break out of the loop
                        break
                    }
                }
            }
            // Everything should have sent by now (or failed)
            if (status !== "Error") {
                if (needToSend <= 0) {
                    setStatus("Success");
                    // If everything has sent, ensure our progress bar shows as much.
                    setMaximumnum(data.length);
                    setCurrentnum(data.length);
                    setPercent(100);
                } else {
                    setStatus("Error");
                    // We set the maximum here as if it fails it shows (x out of y);
                    setMaximumnum(data.length);
                    setFailmessage(needToSend+" photo(s) failed to send.")
                }
            }
        }
    }

    // Only fire handleSubmit() once on load (like componentDidMount)
    useEffect(() => {
        handleSubmit();
        // Adding function to effect dependencies causes infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);
      
    return (
        <div className="card">
            <div className="card-header">
                <font id="FrazerFont_Large">Submit</font>
            </div>
            <div className="card-body px-2">
                <div className="container">
                <div className="row justify-content-center">
                    <br/>
                    <br/>
                    <center>
                        { (status === "Preparing" ) ? <font id="FrazerFont_Medium">Preparing for transmission.<br/>(This may take a few moments)</font> 
                        : (status === "Sending" ) ? <font id="FrazerFont_Medium">Sending Data, Please Wait</font> 
                        : (status === "Success")                    ? <font id="FrazerFont_Medium">Success!</font> 
                        : <font id="FrazerFont_Medium">Something went wrong...</font>
                        }
                    </center>
                    <br/>
                </div>
                <div className="row justify-content-center">
                    <div className="col">
                        <div>
                            {status !== "Error" && <center><font id="FrazerFont_Medium">{status} {currentnum} of {maximumnum}</font></center>}
                            <ProgressBar percentage={percent} /> 
                            <br/>
                        </div>
                        {status === "Success" && (
                            <SubmitSuccess />
                        )}
                        {status === "Error"   && (
                            <SubmitFail failmessage={ failmessage } />
                        )}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default PageSubmit
import React, { useState, createContext } from 'react';

export const WarningContext = createContext();

export const WarningProvider = (props) => {
    const [warnings, setWarnings] = useState([]);
    return (
        <WarningContext.Provider value={[warnings, setWarnings]}>
            { props.children }
        </WarningContext.Provider>
    )
}
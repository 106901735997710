import React, { useState, createContext } from 'react';

export const PhotoContext = createContext();

export const PhotoProvider = (props) => {
    const [photos, setPhotos] = useState([]);
    return (
        <PhotoContext.Provider value={[photos, setPhotos]}>
            { props.children }
        </PhotoContext.Provider>
    )
}
import React, { useState } from 'react';
import { FrazerLogo, Footer } from '../components';
//import { OwnerProvider, PhotoProvider, WarningProvider } from '../contexts';
import { CombinedProvider } from '../contexts';
import { PageForm, PageSubmit, PageNotFound, PageTokenCheck, PageTokenFailed } from '../pages';
import { Router } from '@reach/router';

function App() {
  // State 
  const [tokenstate, setTokenstate] = useState({
    valid: false,
    // Dealer Info from CRM
    dealerinfo: {},
    // If Validation fails, we'd like to display something to the user as to why. Here's a default message.
    errormessage: "Unable to Establish Connection to server.",
    // Keep the token once we retrieve it for when we submit to back-end
    token: "",
  })
  // Style
  const divStyle = {
    maxWidth: '660px',
    width: 'auto',
    height: 'auto'
  }
  // Routing
  const renderTokenValidPaths = () => [
    <PageForm key="pageform" dealerinfo={ tokenstate.dealerinfo }path="/" />,
    <PageSubmit key="pagesubmit" dealerinfo={ tokenstate.dealerinfo } link={tokenstate.token} path="/submit" />,
    <PageNotFound key="pagenotfound" path="/404" default />
  ]
  const renderTokenNotValidPaths = () => [
    <PageTokenCheck 
      key="pagetokencheck" 
      setTokenstate={ setTokenstate }
      tokenstate={ tokenstate }
      path="/" 
    />,
    <PageTokenFailed key="pagetokenfailed" reason={ tokenstate.errormessage } path="/invalid" />,
    <PageNotFound key="pagenotfound" path="/404" default/>
  ]
  
  return (
    <div className="App">
      <form noValidate>
        <div className="container justify-content-center" style={ divStyle } >
            <FrazerLogo />
            <CombinedProvider>
              <Router>
                {tokenstate.valid && renderTokenValidPaths()}
                {!tokenstate.valid && renderTokenNotValidPaths()}
              </Router>
              <br/>
              <br/>
              <Footer />
            </CombinedProvider>
        </div>
      </form>
    </div>
  );
}

export default App;

import React from 'react';
import { LittleMessage } from '../components';

const UserSelect = ({id, onChange, value}) => {
    // When component loads, fill in initial value (otherwise it is set to dl even when data is present)

    return(
        <div className="form-group">
            <font id="FrazerFont_Medium">ID Type: </font>
            <select className="form-control" value={value} id={ id } onChange={ (e)=> onChange(e.target.value) }>
                <option value="dl">Drivers License</option>
                <option value="passport">Passport</option>
                <option value="gov">Government Issued Photo ID</option>
            </select>
            <LittleMessage id={ id+"_m" }/>
        </div>
    )
}

export default UserSelect
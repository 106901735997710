import React from 'react';
import { PhotoSection, LittleMessage } from '../components';

const BusinessCard = ({id}) => {

    return (
        <div className="card">
            <div className="card-header">
                <font id="FrazerFont_Large">Business Documents (Recommended)</font>
            </div>
            <div className="card-body">
                <center>
                    <font id="FrazerFont_Medium">Business Documents that may help prove ownership (Recommended)</font>
                    <br/>
                    <br/>
                    <font id="FrazerFont_Medium">Due to every state's process and documents being different, this step is entirely optional, however we do recommend it as it may speed up the process.</font>
                    <br/>
                    <br/>
                    <font id="FrazerFont_Medium">Some examples of what you could include are:</font>
                    <br/>
                    <br/>
                    <ul className="list-group-flush list-unstyled">
                        <li className="list-group-item"><font id="FrazerFont_Medium">Dealer License</font></li>
                        <li className="list-group-item"><font id="FrazerFont_Medium">Business License</font></li>
                        <li className="list-group-item"><font id="FrazerFont_Medium">Articles of Incorporation</font></li>
                    </ul>
                    <br/>
                    <LittleMessage id={ id+"_m" } />
                    <br/>
                    <PhotoSection id={ id } />
                </center>
            </div>
        </div>
    )
}

export default BusinessCard
import React from 'react';

const ProgressBar = ({percentage}) => {
    const outerStyle = {
        width: "100%",
        height: "20px",
        margin: "15px auto",
        background: "rgb(34,34,34)",
        border: "2px solid rgb(34,34,34)",
        borderRadius: "10px",
    }
    const innerStyle ={
        width: percentage+"%",
        background: "#fcc515",
        position: "relative",
        height: "16px",
        borderRadius: "9px",
        transition: "width .5s ease",
    }
    return (
        <div style={ outerStyle }>
            <div style={ innerStyle }>

            </div>
        </div>
    )
}

export default ProgressBar
import React from 'react';
import greenCheckmark from '../images/green_checkmark_128.png'

const SubmitSuccess = () => {
    // Styling
    const imgStyle = {
        width: 'auto',
        height: 'auto',
        maxWidth: '96px',
        minWidth: '48px'
    }
    return (
        <center>
            <img style={ imgStyle } src={greenCheckmark} alt="Sending Successful" className="img-fluid" />
            <br/>
            <font id="FrazerFont_Medium"><br/>What's next?<br/><br/>
            Your information has been submitted. If any additional information is required, a member of our Verification Team will contact you. An email confirmation will be sent once verification is complete.<br/><br/>
            If you have any questions regarding the status, please don't hesitate to contact us at 888-963-5369.
            </font>
        </center>
    )
}

export default SubmitSuccess
import React, { useContext } from 'react';
import { BusinessInfo, OwnerCard } from '../components';
import { OwnerContext } from '../contexts';
import shortid from 'shortid';

const OwnerSection = ({role, title, cid, companyname, street, city, state, zip}) => {
    const [entries, setEntries] = useContext(OwnerContext);

    const addOwner = () => {
        if (role === "Owner") {
            setEntries(entries => [...entries, {
                title: "Owner",
                entryrole: "owner",
                name: "",
                idtype: "dl",
                idnumber: "",
                id: shortid.generate(),
                removeable: true
            }])
        } else {
            setEntries(entries => [...entries, {
                title: "Admin",
                entryrole: "admin",
                name: "",
                idtype: "dl",
                idnumber: "",
                id: shortid.generate(),
                removeable: true
            }])
        }
            
    }
      
    return(
        <div className="card">
            <div className="card-header">
                <font id="FrazerFont_Large">{ title }</font>
            </div>
            <div id="ownerSection" className="card-body px-2">
                { title === "Ownership" ? (
                    <BusinessInfo 
                    cid={ cid }
                    companyname={ companyname }
                    street={ street }
                    city={ city }
                    state={ state }
                    zip = { zip }
                    />
                    ) : (
                    <div>
                    <center>
                    <font id="FrazerFont_Medium">If you have anyone who is not an owner, but you still want to authorize them to make decisions related to Frazer and your data contained within Frazer, you can specify them here.<br/><br/></font>
                    </center>
                    <br/>
                    </div>
                    )
                }
                <div id="ownerCardHolder">
                    {entries.length > 0 && entries.map(entry => {
                        if (entry.title === role) {
                            return (
                                <OwnerCard 
                                    key={ entry.id } 
                                    id={ entry.id }
                                    title={ entry.title } 
                                    ownername={ entry.name }
                                    owneridtype={ entry.idtype }
                                    owneridnumber={ entry.idnumber } 
                                    removeable={ entry.removeable }
                                    role={ role }
                                /> 
                            )
                        }
                        return null
                    })}
                </div>
                <br/>
                <center>
                {role === "Owner" && (
                  entries.length === 0 && <p id="FrazerFont_Medium">Were you asked to provide the Business Owners Identification for verification?</p>
                  )}
                  <button className="btn btn-dark" id="buttonAddOwner" name="buttonAddOwner" type="button" onClick={addOwner}><font id="FrazerFont_Medium">Add An {role}</font></button>
                </center>
                <br/>
                <br/>
            </div>
        </div>
    )
    
}

export default OwnerSection
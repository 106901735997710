import React, { useState, createContext } from 'react'

export const OptionalContext = createContext();

export const OptionalProvider = ({children}) => {
    // Set initial state for one of each type
    // 'ask', 'yes', 'no'
    const [optional, setOptional] = useState({
            admin: 'ask',
            business: 'ask'
        });
    return (
        <OptionalContext.Provider value={[optional, setOptional]}>
            {children}
        </OptionalContext.Provider>
    )
}
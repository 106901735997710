import React, { useContext } from 'react';
import { BusinessCard } from '../components';
import { OwnerContext } from '../contexts';

const BusinessSection = () => {
    const [entries] = useContext(OwnerContext);
    return(
        <div>
            {entries.length > 0 && entries.map(entry => {
                if (entry.title === "Business") {
                    return (
                        <BusinessCard 
                            key={ entry.id } 
                            id={ entry.id }
                        /> 
                    )
                }
                return null
            })}
        </div>
    )
}

export default BusinessSection
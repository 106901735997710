import React from 'react'
import { OwnerProvider, PhotoProvider, WarningProvider, OptionalProvider } from '../contexts';

export const CombinedProvider = (props) => {
    return (
        <OwnerProvider>
            <PhotoProvider>
                <WarningProvider>
                    <OptionalProvider>
                        { props.children }
                    </OptionalProvider>
                </WarningProvider>
            </PhotoProvider>
        </OwnerProvider>
    )
}
import React from 'react';

const PageNotFound = () => {

    return (
        <div className="card">
            <div className="card-header">
                <font id="FrazerFont_Large">Error</font>
            </div>
            <div className="card-body px-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <br/>
                        <br/>
                        <font id="FrazerFont_Large">404 - Not Found</font>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound
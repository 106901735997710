import React from 'react';

const YesOrNo = ({title, type, onSelect, collapse}) => {
    return (
        <div className="card">
            <div className="card-header">
                <font id="FrazerFont_Large">{ title }</font>
                {collapse === 'no'&& (
                    <button className="btn btn-outline-light" type="button" style={{ float: 'right' }} onClick={ () => onSelect('ask', type) }>
                        <font id="FrazerFont_Medium">+</font>
                    </button>
                )}
            </div>
            {collapse === 'ask' && (
                <div className="card-body px-2">
                    <div className="container">
                        <div className="row">
                            <br/>
                            {type === "admin" ? (
                                <font id="FrazerFont_Medium">Do you need to include any Authorized Personnel or Administrators who are not Owners?</font>
                            ) : (
                                <font id="FrazerFont_Medium">Would you like to include any photos of Business documents that may help verify Ownership?</font>
                            )}
                            <br/>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-4">
                            <center>
                                <button className="btn btn-dark" type="button" onClick={ () => onSelect('yes', type) }>
                                    <font id="FrazerFont_Medium">Yes</font>
                                </button>
                            </center>
                            </div>
                            <div className="col-4">
                            <center>
                                <button className="btn btn-dark" type="button" onClick={ () => onSelect('no', type) }>
                                    <font id="FrazerFont_Medium">No</font>
                                </button>
                            </center>
                            </div>
                        </div>
                    </div>
                </div>
            )}
                
        </div>
    )
}

export default YesOrNo
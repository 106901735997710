import React from 'react';
import redX from '../images/red_x_128.png'

const PageTokenFailed = ({ reason }) => {
    // Styling
    const imgStyle = {
        width: 'auto',
        height: 'auto',
        maxWidth: '96px',
        minWidth: '48px'
    }
    return (
        <div className="card">
            <div className="card-header">
                <font id="FrazerFont_Large">Error</font>
            </div>
            <div className="card-body px-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <br/>
                        <br/>
                        <font id="FrazerFont_Large">Token Validation Failed</font>
                    </div>
                    <div className="row justify-content-center">
                        <img style={ imgStyle } src={redX} alt="Invalid" className="img-fluid" />
                    </div>
                    <div className="row">
                        <p></p>
                    </div>
                    <div className="row justify-content-center">  
                        <center><font id="FrazerFont_Medium">{ reason }</font></center>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageTokenFailed
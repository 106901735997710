import React, { useContext } from 'react';
import { YesOrNo, OwnerSection, BusinessSection } from '../components';
import { OwnerContext, OptionalContext } from '../contexts';
import shortid from 'shortid';

const OptionalSections = () => {
    // State / Context
    const [,setEntries] = useContext(OwnerContext); 
    const [optional, setOptional] = useContext(OptionalContext);
    
    
    // Set state and our contexts as necessary.
    const clickYesOrNo = (decision, type) => {
        if (type === "admin") {
            // Update context
            setOptional({...optional, admin: decision});
            if (decision === 'yes') {
                // Add in a blank entry so it renders another card.
                setEntries(entries => [...entries, {
                    title: "Admin",
                    entryrole: "admin",
                    name: "",
                    idtype: "dl",
                    idnumber: "",
                    id: shortid.generate(),
                    removeable: true
                }])
            }
        } else {
            // Not admin, so assume business
            // Update context
            setOptional({...optional, business: decision});
            if (decision === 'yes') {
                // Add in a blank entry so it renders another card.
                setEntries(entries => [...entries, {
                    title: "Business",
                    entryrole: "business",
                    name: "",
                    idtype: "",
                    idnumber: "",
                    id: shortid.generate(),
                    removeable: true
                }])
            } 
        }
    }
    return (
        <div>
            {optional.admin === 'yes' ? (
                <div>
                    <br/>
                    <OwnerSection title="Authorized Personnel / Administrators (Optional)" role="Admin"/>
                </div>
            ) : (
                <div>
                    <br/>
                    <YesOrNo 
                        title="Authorized Personnel / Administrators (Optional)"
                        type="admin"
                        collapse={optional.admin}
                        onSelect={clickYesOrNo}
                    />
                </div>
            )}
            {optional.business === 'yes' ? (
                <div>
                    <br/>
                    <BusinessSection />
                </div>
            ) : (
                <div>
                    <br/>
                    <YesOrNo
                        title="Business Documents (Recommended)"
                        type="business"
                        collapse={optional.business}
                        onSelect={clickYesOrNo}
                    />
                </div>
            )}
        <br/>
        </div>
    )
}

export default OptionalSections
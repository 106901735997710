import React from 'react';

const BusinessInfo = ({cid, companyname, street, city, state, zip}) => {
    return(
        <div>
            <center>
            <font id="FrazerFont_Medium">Please fill out the information for the Owner(s) of the following Dealership:<br/><br/>Customer ID: { cid }<br/>{ companyname }<br/>{ street }<br/>{ city+", "+state+" "+zip }</font>
            </center>
            <br/>
        </div>
    )
}

export default BusinessInfo
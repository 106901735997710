import React, { useContext } from 'react';
import { PhotoContext } from '../contexts';

const PhotoEntry = ({id, imgname, imgurl}) => {
    const imgStyle = {
        maxWidth: '96px',
        minWidth: '48px',
        maxHeight: '96px',
        minHeight: '48px'
    }
    const textStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
    const [,setPhotos] = useContext(PhotoContext);

    const deletePhoto = () => {
        setPhotos(photos => photos.filter(photo => photo.id !== id))
    }
    return(
        <div className="row align-items-center justify-content-around mt-3">
            <div className="col justify-content-center">
                <img style={ imgStyle } src={ imgurl } alt="hurhur" className="img-fluid"/>
            </div>
            <div className="col justify-content-center" style={ textStyle }>
                { imgname }
            </div>
            <div className="col-2 justify-content-center">
                <center>
                <button className="btn btn-danger justify-content-center" type="button" id={ id+"_imgButton" } onClick={deletePhoto}>
                    <font id="FrazerFont_Medium">X</font>
                </button> 
                </center>
            </div>
        </div>
        
    )
}

export default PhotoEntry;
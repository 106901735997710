import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import linkIcon from '../images/link-icon-black-128x128.png'

const PageTokenCheck = ({ tokenstate, setTokenstate }) => {
    // Styling
    const imgStyle = {
        width: 'auto',
        height: 'auto',
        maxWidth: '96px',
        minWidth: '48px'
    }

    let url = `${process.env.REACT_APP_BACKEND_URL}/portal/getDealerInfo`
    
    // Pass that link to the back-end to validate the token in the link
    const verifyToken = async () => {
        // Grab anything after the "?" in the URL
        var param = new URLSearchParams(document.location.search);
        // Very that a link is included, if so get it.
        if (param.has('link')) {
            var link = param.get("link");
            // Try and make a request
            try {
                const response = await fetch(url+"?link="+link, {method: 'POST'});
                const res = await response.json();
                // Get the status of the response and to determine what to show user
                var status = response.status;
                if (status === 200) {
                    // Set Info to the contents of the JSON, which will trigger the useEffect of the parent component
                    setTokenstate({...tokenstate, dealerinfo: res, valid: true, token: link});
                } else {
                    // Not valid, navigate to PageTokenFailed, set the reason
                    switch (status) {
                        case 502:
                            setTokenstate({...tokenstate, errormessage: "Error interacting with another service, please try again later."});
                            break;
                        case 404:
                            setTokenstate({...tokenstate, errormessage: "Not found! Please try again later. If issue persists call 888-963-5369 and request a new Verification Link"});
                            break;
                        default:
                            setTokenstate({...tokenstate, errormessage: "Invalid or Expired Link. Please call 888-963-5369 and request a new Verification Link."});
                    }
                    navigate("/invalid");
                }
            } catch(e) {
                setTokenstate({...tokenstate, errormessage: "Invalid or Expired Link. Please call 888-963-5369 and request a new Verification Link."});
                navigate("/invalid");
            }
        } else {
            setTokenstate({...tokenstate, errormessage: "Missing Link. Please call 888-963-5369 and request a new Verification Link."});
            navigate("/invalid");
        }
    }    
    // Only fire sendData() once on load (like componentDidMount)
    useEffect(() => {
        verifyToken();
        // Adding function to effect dependencies causes infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    return (
        <div className="card">
            <div className="card-header">
                <font id="FrazerFont_Large">Establishing Secure Connection</font>
            </div>
            <div className="card-body px-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <br/>
                        <br/>
                        <font id="FrazerFont_Large">Validating Token</font>
                    </div>
                    <div className="row justify-content-center">
                        <img style={ imgStyle } src={linkIcon} alt="Link" className="img-fluid" />
                    </div>
                    <div className="row">
                        <p></p>
                    </div>
                    <div className="row justify-content-center">  
                        <font id="FrazerFont_Medium">Verifying Link and Retrieving Dealership Information</font>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageTokenCheck
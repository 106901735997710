import React from 'react';
import logo from '../images/frazerlogo.png'

const FrazerLogo = () => {
    return(
        <div>
            <center>
            <br/>
            <img src={logo} alt="Frazer Verification"/>
            </center>
            <br/>
            <br/>
        </div>
    )
}

export default FrazerLogo
import React, { useState, createContext } from 'react'

export const OwnerContext = createContext();

export const OwnerProvider = ({children}) => {
    // Set initial state for one of each type
    const [entries, setEntries] = useState([

    ]);
    return (
        <OwnerContext.Provider value={[entries, setEntries]}>
            {children}
        </OwnerContext.Provider>
    )
}

import React, { useContext } from 'react';
import { LittleMessage, PhotoSection, UserField, UserSelect } from '../components';
import { OwnerContext, PhotoContext } from '../contexts';

const OwnerCard = ({id, removeable, role, title, ownername, owneridtype, owneridnumber}) => {
    // State/Context
    const [entries, setEntries] = useContext(OwnerContext);
    const [,setPhotos] = useContext(PhotoContext);
    
    const entry = entries.find(entry=>entry.id===id) 
    // Input sanitization, prevents XSS/injection attacks.
    const sanitize = (str, maxlength) => {
        // Scrub string of bad characters
        // eslint-disable-next-line no-useless-escape
        str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
        // Ensure it does not exceed maxlength
        str = str.substring(0,maxlength);
        // Return the clean string
        return str
    }

    // Inputs
    const updateName = (name) => {
        // Update our context (and ensure the field displays filtered value)
        setEntries(entries.map(entry => {
                if (entry.id === id)  {
                    var str = sanitize(name,50);
                    entry.name = str;
                } 
                return entry
        }))
    }

    const updateIDtype = (idtype) => {
        // Update our context (And verify valid id type)
        setEntries(entries.map(entry => {
            if (entry.id === id)  {
                // This is a <select> element, whereas others are <input>
                var str = sanitize(idtype,8);
                if (str === "dl" || str === "passport" || str === "gov") {
                    entry.idtype = str;
                } else {
                    // Someone is changing the value of the options in the console. Do NOT accept it.
                    console.log("Unexpected selection");
                }
            }    
            return entry
        }))
    }

    const updateIDnum = (idnum) => {
        // Update our context (and ensure the field displays filtered value)
        setEntries(entries.map(entry => {
            if (entry.id === id)  {
                var str = sanitize(idnum,25);
                entry.idnumber = str;
            } 
            return entry
    }))
}

    const deleteCard = () => {
        // Delete entry from OwnerContext
        setEntries(entries => entries.filter(entry => entry.id !== id))
        // Delete any photos from PhotoContext that was associated with entry.
        setPhotos(photos => photos.filter(photo => photo.refid !== id))
    }

    
    return(
        <div>
            <div className="card" id={ id+"_ownerCard" }>
                <div className="card-header">
                    <font id="FrazerFont_Medium">{ title }</font>
                    { removeable && <button className="btn btn-danger" id={ id+"_ownerCardDelete" } type="button" style={{ float: 'right' }} onClick={deleteCard}><font id="FrazerFont_Medium">X</font></button>}
                </div>
                <div className="card-body">
                    <table className="table-responsive">
                        <table className="table table-fixed">
                            <tbody>
                                <tr>
                                    <UserField 
                                        label={"Name of "+role+":"}
                                        placeholder="Example: John Q Sample or Jane Q Doe" 
                                        id={ id+"_ownerName" } 
                                        value={ entry.name } 
                                        //key={ shortid.generate() }
                                        onChange={ updateName }
                                    />
                                </tr>
                                <tr>
                                    <UserSelect 
                                        value={ entry.idtype } 
                                        //key={ shortid.generate() } 
                                        onChange={ updateIDtype }
                                        id={ id+"_ownerIDtype" }
                                    />
                                </tr>
                                <tr>
                                    <UserField 
                                        label="ID Number:" 
                                        placeholder="Example: 1234" 
                                        id={ id+"_ownerIDnum" } 
                                        value={ entry.idnumber } 
                                        //key={ shortid.generate() } 
                                        onChange={ updateIDnum }
                                    />
                                </tr>
                                <tr>
                                    <br/>
                                    <center>
                                    <font id="FrazerFont_Medium">Finally, we need a photo of this person's ID.</font>
                                    <LittleMessage id={ id+"_m" } />
                                    </center>
                                    <br/>
                                </tr>
                            </tbody>
                        </table>
                    </table>
                    <PhotoSection id={ id } />
                </div>
            </div>
            <br/>
        </div>
    )
}

export default OwnerCard
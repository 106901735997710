import React,{ useContext } from 'react';
import { WarningContext } from '../contexts';

const LittleMessage = ({ id }) => {
    const [warnings] = useContext(WarningContext);
    // The text content of this div is populated when fields are validated and there is an issue.
    return (
        <small className="form-text text-danger">
            {warnings.length > 0 && warnings.map(warning => {
                if (warning.refid === id) {
                    return warning.message;
                }
                return null
            })}
        </small>
    )
}

export default LittleMessage
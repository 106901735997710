import React from 'react';
import { LittleMessage } from '../components';

const UserField = ({label, placeholder, id, onChange, value}) => {
    // When component loads, fill in initial value (otherwise it is blank when data is present)
    return(
        <div className="form-group">
            <font id="FrazerFont_Medium">{ label} </font>
            <input className="form-control" type="text" value={value} placeholder={ placeholder } id={ id } onChange={ (e)=>onChange(e.target.value) } required/>
            <LittleMessage id={ id+"_m" }/>
        </div>
    )
}

export default UserField

